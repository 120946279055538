export default class DictionaryTableItem {
    dictionaryId = 0;
    dictionaryStatus = 0;
    dictionaryParentId = 0;
    dictionaryParentName = "";
    dictionarySortId = 0;
    dictionaryCode = "";
    dictionaryName = "";
    dictionaryValueTypeId = 0;
    dictionaryValueTypeIdName = "";
    dictionaryRemark = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
