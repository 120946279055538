import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import SelectLevel from "@/modules/select-level/select-level.vue";
import DraggableListview from "@/modules/draggable-listview/draggable-listview.vue";
import DictionaryTableItem from "@/entity/system/DictionaryTableItem";
import API from "@/api/system/dictionary";

@Options({
    name: "app-system-dictionary",
    components: {
        "app-select-level": SelectLevel,
        "app-draggable-listview": DraggableListview,
    },
})
export default class Dictionary extends BaseTableMixins<DictionaryTableItem> implements Partial<BaseTableInterface<DictionaryTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new DictionaryTableItem();
        this.editModalItem = new DictionaryTableItem();
    }

    //锁定列数据实体
    public getLockItem(): any {
        return {
            dictionaryParentId: {
                type: "router",
                name: "dictionary_id",
                title: "上级公共字典",
                parent: -1,
                value: null,
                openFn: this.dictionaryParentIdLevelModalOpen,
            },
        };
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    // //列表回调
    // public listCallback(items: Array<DictionaryTableItem>): void {
    //     return;
    // }

    public valueTypeOptions: any[] = [
        { label: "默认ID", value: 1 },
        { label: "排序ID", value: 2 },
    ];

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new DictionaryTableItem();
        this.editModalItem = new DictionaryTableItem();
        this.editModalItem.dictionaryStatus = 1;
        this.editModalTitle = "添加公共字典";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.dictionaryName)) return "公共字典名称不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.dictionaryStatus)) return "公共字典状态不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.dictionaryParentId)) return "上级公共字典不能为空！";
        if (this.editModalItem.dictionaryParentId == -1) {
            if (this.$tools.isEmpty(this.editModalItem.dictionaryCode)) return "调用代码不能为空！";
            if (this.$tools.isEmpty(this.editModalItem.dictionaryValueTypeId)) return "值类型不能为空！";
        }
        return null;
    }
    // //添加回调
    // public addModalCallback(res: any): void {
    //     return;
    // }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改公共字典 (" + this.editModalItem.dictionaryName + ")";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.dictionaryId)) return "公共字典ID不能为空！";
        return this.addSubmitChecking();
    }
    // //修改回调
    // public modModalCallback(res: any): void {
    //     return;
    // }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除公共字典",
            message: "公共字典: " + this.editModalItem.dictionaryName + "<br>请确认删除？",
        };
    }
    // //删除提示框配置
    // public delAlertSetting(): void {
    //     return;
    // }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.dictionaryId)) return "公共字典ID不能为空！";
        return null;
    }
    // //删除回调
    // public delAlertCallback(res: any): void {
    //     return;
    // }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "公共字典状态",
            message: "公共字典: " + this.editModalItem.dictionaryName + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.dictionaryStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.dictionaryStatus = this.editModalItem.dictionaryStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.dictionaryId)) return "公共字典ID不能为空！";
        return null;
    }
    // //状态切换回调
    // public toggleStatusCallback(): void {
    //     return;
    // }

    //排序弹窗配置
    public sortModalSetting(): void {
        this.editSortBaseTitle = "公共字典排序";
    }
    //排序提交检测
    public sortSubmitChecking(): string {
        if (this.editModalItem.dictionaryParentId < -1) {
            this.toast.error("上级ID不能小余-1");
            return;
        }
        return null;
    }

    //dictionaryParentId搜索字段
    private dictionaryParentIdLevelSearchField: any = [
        { field: "dictionary_name", name: "名称" },
        { field: "dictionary_remark", name: "说明" },
    ];
    //dictionaryParentId层级选择器打开
    public dictionaryParentIdLevelModalOpen() {
        (this.$refs.dictionaryParentIdLevel as any).openModal();
    }
    //dictionaryParentId层级选择器提交
    public async dictionaryParentIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().dictionaryParentId : item;
        this.tableRequestItem.lockItem.dictionaryParentId.value = curItem.value;
        this.tableRequestItem.lockItem.dictionaryParentId.title = curItem.title;
        this.tableRequestItem.lockItem.dictionaryParentId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    get dictionaryParentIdSelectApi() {
        return {
            api: (query: any) => API.idSelect(query),
            parent: "dictionary_parent_id",
            status: "dictionary_status",
            name: "dictionary_name",
            id: "dictionary_id",
            cid: this.editModalItem.dictionaryParentId,
            mid: this.editModalItem.dictionaryId,
            extend: true,
        };
    }

    get isTopLevel() {
        return this.editModalItem && Number(this.editModalItem.dictionaryParentId) === -1;
    }
}
